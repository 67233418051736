import store from '../store'

import axios from 'axios'

let axiosInstance = axios.create({
  // baseURL: "http://localhost:3000",
  timeout: 20000, // cloud run cold start needs more than 10s
})

axiosInstance.interceptors.request.use(
  config => {
    const token = store.getState().userReducer.token
    config.headers.authorization = `Bearer ${token}`
    // config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// axiosInstance.interceptors.response.use(
//   response => response,
//   error => {
//     if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
//       return Promise.reject(error);
//     }

//     if (error.response && error.response.data.message) {
//       const { data: { message } } = error.response
//       toaster.notify(message)
//     } else {
//       toaster.danger('Something went wrong')
//     }
//   }
// )

export { axiosInstance }
